import * as React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import BannersCarousel from "./BannersCarousel";

export default function BlogWidget() {
    const { t } = useTranslation();

    return (
        <div className="blog-adv position-sticky">
            <p className="h6">{t('advertisementWord')}:</p>
            <BannersCarousel />
            <p className="advertisement-text">{t('advertisementText')}: info@avzpro.com.ua</p>
        </div>
    )
}