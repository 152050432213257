import * as React from "react";
import BlogCategories from "../blog/BlogCategories";
import {useTranslation} from "gatsby-plugin-react-i18next";

export default function BlogWidget2() {
    const { t } = useTranslation();

    return (
        <div className="blog-widgets position-sticky">
            <BlogCategories title={t('blogCategories')} />
        </div>
    )
}