import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {Col} from "react-bootstrap";
import Slider from "react-slick";
import {bannerSliderSettings} from "../../constants";

export default function BannersCarousel() {

    return (
        <Slider className="banner-slider" {...bannerSliderSettings}>
            <Col xxl={12}>
                <a href="//izstekla.net.ua/" target="__blank">
                    <StaticImage
                        src="../../../images/banners/izSTEKLA.png"
                        width={640}
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="izSTEKLA"
                        className="w-100 mb-2 mb-xl-3"
                    />
                </a>
            </Col>
            <Col xxl={12}>
                <a href="//bit.ly/36SFKZ8" target="__blank">
                    <StaticImage
                        src="../../../images/banners/host_1.png"
                        width={640}
                        quality={97}
                        formats={["auto", "webp", "avif"]}
                        alt="Hosting"
                        className="w-100 mb-2 mb-xl-3"
                    />
                </a>
            </Col>
            <Col xxl={12}>
                <StaticImage
                    src="../../../images/banners/640x280.png"
                    width={640}
                    quality={85}
                    formats={["auto", "webp", "avif"]}
                    alt="No banner"
                    className="w-100 mb-2 mb-xl-3 blog-adv__no-pic"
                />
            </Col>
            <Col xxl={12}>
                <StaticImage
                    src="../../../images/banners/640x280.png"
                    width={640}
                    quality={85}
                    formats={["auto", "webp", "avif"]}
                    alt="No banner"
                    className="w-100 mb-2 mb-xl-3 blog-adv__no-pic"
                />
            </Col>
        </Slider>
    )
}