import * as React from "react";
import {Link, useTranslation} from "gatsby-plugin-react-i18next";

export default function BlogCategories({title}) {
    const { t } = useTranslation();

    return (
        <div className="blog-categories">
            <h2>{t(title)}:</h2>
            <Link to={'/category/pravilnoe-seo'} className="d-inline-block py-1 py-xl-2 mb-1 mb-md-2 me-1 me-md-2">{t('seoBlog')}</Link>
            <Link to={'/category/sayt-pod-biznes'} className="d-inline-block py-1 py-xl-2 mb-1 mb-md-2 me-1 me-md-2">{t('seoBusiness')}</Link>
            <Link to={'/category/poleznoe-internet'} className="d-inline-block py-1 py-xl-2 mb-1 mb-md-2 me-1 me-md-2">{t('seoInternet')}</Link>
        </div>
    )
}